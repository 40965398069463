.mainbox{
    position: relative;
    top:-100px;
    z-index: -2;
    height:1000px;
    background: url("./home.jpeg") no-repeat;
    background-size: cover;
    padding-left: 10%;
    padding-right: 10%;
}

.header1{
    padding-top:300px;
    color:white;
    font-size: 32px;
    margin-bottom: 30px;
    text-shadow: black 0px 0px 10px;
}
.para{
    color:white;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 60px;
    width:60%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: black 0px 0px 10px;
}
.products{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}
.productBox{
    display: flex;
    flex-direction: column;
    margin:10px;
}
.logo{
    border-radius: 50%;
}
@media (max-width: 900px) {
    .para{
        width:90%;
    }
}@media (max-width: 725px) {
    .products{
        flex-direction: column;
    }
}