.footerBottom{
   background:black;
   color:white;
   height:80px;
   text-align: center; 
   padding-top: 30px;
   margin-top: 20px;
}
.imageViewer{
    display:'flex';
    flex-direction:'row'; 
    justify-content:'center'; 
}
.imgBox{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width:60%;
    justify-content: center;
    margin-top: 40px;
}
.productBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1PX SOLID BLACK;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.textBox{
    display: flex;
    flex-direction: column;
    width:auto;
    text-align: start;
}
.textHeader{
    margin-bottom: 10px;
    margin-left: 5%;
}
.textPara{
    margin-left: 5%;
    max-width: 100%;
}
.textHeaderInv{
    margin-bottom: 10px;
    margin-right: 5%;
}
.textParaInv{
    margin-right: 5%;
    max-width: 100%;
}
.para{
    margin-left: auto;
    margin-right: auto;
    width:60%;
}
.flames{
    width: 200px;
    margin-right: 5%;
}
@media (max-width: 800px) {
    .imgBox,
    .para{
        width:80%;
    }
}

@media (max-width: 600px) {
    .productBox{
    flex-direction: column;
    }
    .secondPic{
        order:0;
    }
    .textBox{
        order:1;
    }
    .textHeaderInv,
    .textParaInv,
    .flames{
        margin-left: 5%;
    }
}