/*Desktop View*/
.header {
    display: flex;
    background: transparent;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px 0px 40px;
    height: 100px;
    width:80%;
    margin-left: auto;
    margin-right: auto;
  }
  .logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
  }
  .logo {
    width: 110px;
    height: 110px;
    background: url("./logo-new.png") no-repeat;
    background-size: cover;
    border-radius: 50%;
  }
  .nav-options {
    padding-right: 25px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 50px;
    list-style-type: none;
  }
  .mobile-option {
    display: none;
  }
  
  .option :hover {
    color:red;
    background:white;
  }

  .mobile-menu {
    display: none;
  }
  .menu-icon{
    cursor: pointer;
    height: 30px;
    width:30px;
    color: black;
    display: none;
  }
  .navText{
    font-size: 20px;
    font-weight: 900;
    text-shadow: white 0px 0px 10px;
  }
  @media (max-width: 700px) {
    /*Mobile View */
    .header {
      padding: 0px 10px;
    }
    .nav-options {
      display: flex;
      width: 100%;
      height: 250px;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
      grid-gap: 0px;
    }
    .nav-options.active {
      background: #9899d1;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      align-content: center;
      padding-left: 0px;
    }
  
    .menu-icon {
      width: 30px;
      height: 30px;
      display: unset;
    }
    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10vw;
      padding: 30px 0px;
    }
    .sign-up {
      background: rgb(222, 9, 241);
      border-radius: 3px;
      color: white;
      padding: 20px 0px;
      width: 80%;
      align-self: center;
      border-radius: 3px;
    }
  
    .signin-up {
      display: none;
    }
    .logo{
      width:80px;
      height:80px;
      margin-top:0px;
    }
  }
  .mobile-menu {
    display: block;
  }
  