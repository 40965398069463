@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);

/*Desktop View*/
.header {
    display: -webkit-flex;
    display: flex;
    background: transparent;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 40px 0px 40px;
    height: 100px;
    width:80%;
    margin-left: auto;
    margin-right: auto;
  }
  .logo-nav {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
  }
  .logo-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-left: 10px;
  }
  .logo {
    width: 110px;
    height: 110px;
    background: url(/static/media/logo-new.8232ccd4.png) no-repeat;
    background-size: cover;
    border-radius: 50%;
  }
  .nav-options {
    padding-right: 25px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 50px;
    list-style-type: none;
  }
  .mobile-option {
    display: none;
  }
  
  .option :hover {
    color:red;
    background:white;
  }

  .mobile-menu {
    display: none;
  }
  .menu-icon{
    cursor: pointer;
    height: 30px;
    width:30px;
    color: black;
    display: none;
  }
  .navText{
    font-size: 20px;
    font-weight: 900;
    text-shadow: white 0px 0px 10px;
  }
  @media (max-width: 700px) {
    /*Mobile View */
    .header {
      padding: 0px 10px;
    }
    .nav-options {
      display: -webkit-flex;
      display: flex;
      width: 100%;
      height: 250px;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      -webkit-flex-direction: column;
              flex-direction: column;
      list-style-type: none;
      grid-gap: 0px;
    }
    .nav-options.active {
      background: #9899d1;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      -webkit-align-content: center;
              align-content: center;
      padding-left: 0px;
    }
  
    .menu-icon {
      width: 30px;
      height: 30px;
      display: unset;
    }
    .option {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      height: 10vw;
      padding: 30px 0px;
    }
    .sign-up {
      background: rgb(222, 9, 241);
      border-radius: 3px;
      color: white;
      padding: 20px 0px;
      width: 80%;
      -webkit-align-self: center;
              align-self: center;
      border-radius: 3px;
    }
  
    .signin-up {
      display: none;
    }
    .logo{
      width:80px;
      height:80px;
      margin-top:0px;
    }
  }
  .mobile-menu {
    display: block;
  }
  
.App {
  font-family: Montserrat;
  font-weight: 600;
  text-align: center;
}

a {
  text-decoration: none;
  color: black;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.jumbotron_mainbox__2Nm-4{
    position: relative;
    top:-100px;
    z-index: -2;
    height:1000px;
    background: url(/static/media/home.5319e186.jpeg) no-repeat;
    background-size: cover;
    padding-left: 10%;
    padding-right: 10%;
}

.jumbotron_header1__iY5ob{
    padding-top:300px;
    color:white;
    font-size: 32px;
    margin-bottom: 30px;
    text-shadow: black 0px 0px 10px;
}
.jumbotron_para__BRGrR{
    color:white;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 60px;
    width:60%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: black 0px 0px 10px;
}
.jumbotron_products__3IbC6{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    -webkit-justify-content: center;
            justify-content: center;
}
.jumbotron_productBox__1dqLl{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin:10px;
}
.jumbotron_logo__2kl8n{
    border-radius: 50%;
}
@media (max-width: 900px) {
    .jumbotron_para__BRGrR{
        width:90%;
    }
}@media (max-width: 725px) {
    .jumbotron_products__3IbC6{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
.footer_footerBottom__2hSni{
   background:black;
   color:white;
   height:80px;
   text-align: center; 
   padding-top: 30px;
   margin-top: 20px;
}
.footer_imageViewer__2EYo3{
    display:'flex';
    -webkit-flex-direction:'row';
            flex-direction:'row'; 
    -webkit-justify-content:'center'; 
            justify-content:'center'; 
}
.footer_imgBox__2auXe{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width:60%;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 40px;
}
.footer_productBox__20W6X{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    border-bottom: 1PX SOLID BLACK;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.footer_textBox__3N3nA{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width:auto;
    text-align: start;
}
.footer_textHeader__3jc8f{
    margin-bottom: 10px;
    margin-left: 5%;
}
.footer_textPara__2dXMh{
    margin-left: 5%;
    max-width: 100%;
}
.footer_textHeaderInv__RSGsC{
    margin-bottom: 10px;
    margin-right: 5%;
}
.footer_textParaInv__1Zwj1{
    margin-right: 5%;
    max-width: 100%;
}
.footer_para__3xbeJ{
    margin-left: auto;
    margin-right: auto;
    width:60%;
}
.footer_flames__1odWW{
    width: 200px;
    margin-right: 5%;
}
@media (max-width: 800px) {
    .footer_imgBox__2auXe,
    .footer_para__3xbeJ{
        width:80%;
    }
}

@media (max-width: 600px) {
    .footer_productBox__20W6X{
    -webkit-flex-direction: column;
            flex-direction: column;
    }
    .footer_secondPic__PSE3d{
        -webkit-order:0;
                order:0;
    }
    .footer_textBox__3N3nA{
        -webkit-order:1;
                order:1;
    }
    .footer_textHeaderInv__RSGsC,
    .footer_textParaInv__1Zwj1,
    .footer_flames__1odWW{
        margin-left: 5%;
    }
}
.contact_footerBottom__2y2hL{
    background:black;
    color:white;
    height:80px;
    text-align: center; 
    padding-top: 30px;
    position: absolute;
    bottom:0px;
    width:100%;
 }
